
export const environment = {
  production: false,

  // need to define endpoint
  url: "https://app.fatagent.com/"
  //  url: "https://dev.fatagent.com/"
  // url: "http://127.0.0.1:8000/"
  // url: "http://192.168.1.48:80"
};

